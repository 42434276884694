import React, { useState, useEffect } from 'react'
import useToggle from 'react-use/lib/useToggle'
import useWindowScroll from 'react-use/lib/useWindowScroll'
import Container from 'react-bootstrap/Container'
import logoLight from '../images/makenode.png'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'

const navigator = link => e => {
  e.preventDefault()
  navigate(link)
}

const ToggleUl = styled.ul`
  ${props => (props.show ? 'display: block !important;' : '')}
`

export default () => {
  const [isOpen, toggleOpen] = useToggle(false)
  const [isScrollPosTop, setScrollPosTop] = useState(true)
  const { y } = useWindowScroll()

  useEffect(() => {
    if (y > 0 && isScrollPosTop) {
      setScrollPosTop(false)
    } else if (y <= 0 && !isScrollPosTop) {
      setScrollPosTop(true)
    }
  }, [y, isScrollPosTop])

  return (
    <header
      id="header"
      className={`page-section full-header ${
        isScrollPosTop ? '' : 'sticky-header'
      }`}
    >
      <div id="header-wrap">
        <Container>
          <div id="primary-menu-trigger" onClick={toggleOpen}>
            <i className="icon-reorder" />
          </div>

          <div id="logo">
            <a
              href="/#home"
              className="standard-logo"
              onClick={navigator('#home')}
            >
              <img src={logoLight} alt="gigmade" />
            </a>
            <a
              href="/#home"
              className="retina-logo"
              onClick={navigator('#home')}
            >
              <img src={logoLight} alt="gigmade" />
            </a>
          </div>
          {/* Primary Navigation ====================================== */}
          <nav id="primary-menu" className="one-page-menu">
            <ToggleUl show={isOpen}>
              <li /*className="current"*/>
                <a href="/#home" onClick={navigator('#home')}>
                  <div>Home</div>
                </a>
              </li>
              <li>
                <a href="/#about" onClick={navigator('#about')}>
                  <div>About</div>
                </a>
              </li>
              <li>
                <Link to="/demo">demo</Link>
              </li>
              <li>
                <a href="/#feedback" onClick={navigator('#feedback')}>
                  <div>feedback</div>
                </a>
              </li>
            </ToggleUl>
          </nav>
          {/* #primary-menu end */}
        </Container>
      </div>
    </header>
  )
}
